<template>
  <mt-popup v-model="isShowPopup" position="bottom" class="popup_box">
    <div class="listBox">
      <iHeader
        backType="blank"
        :headStyle="
          ['msd', 'dsm'].includes($cookies.get('tenant'))? {background: '#fff',color: '#13161B'}
            : {background: 'var(--themeColor)', color: '#fff'}"
        :backEvent="true"
        @back-event="choisePersonGoBack()"
        :back="true">
        <div slot="headText">
          <span class="head-txt">
           {{ $cookies.get('tenant') == 'novartis'?'参会人列表':'联系人选择'}}
          </span>
        </div>
      </iHeader>
      <div class="sercheBox">
        <SearchRow @search-call="onSearch" placeholder="搜索联系人" v-model="contacts"></SearchRow>
      </div>
      <div class="choiceAdress" @scroll="onScroll($event)">
        <div class="povinceListcontent">
          <div class="weui-cells" v-if="serchDataList.length > 0">
            <div v-for="(items, index) in serchDataList" :key="index">
              <div class="pyMain" :id="'_' + items.py" v-if="index != 0 && serchDataList[index - 1].py != serchDataList[index].py">
                {{ items.py }}
              </div>
              <div class="pyMain" :id="items.py" v-if="index == 0">
                {{ items.py }}
              </div>
              <!-- flag == index && -->
              <div class="weui-cell weui-check__label">
                <div class="weui-cell__bd">
                  <p class="namebox">{{ items.name }}</p>
                  <p
                    @click="chosePhone(items, phoneNumber)"
                    @touchend="touchPhoneItem(index, nindex)"
                    :class="['phoneNumber', flagI == index && flag == nindex && 'choiseLi']"
                    v-for="(phoneNumber, nindex) in items.phoneNumbers"
                    :key="nindex"
                  >
                    {{ phoneNumber.phoneNumber }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p v-else class="noinfor">{{ $cookies.get('tenant') == 'novartis'?'请先在会议详情页添加参会人':'暂时没有发现联系人'}}</p>
        </div>
      </div>
      <div class="navList">
        <ul>
          <template v-for="(item, indexs) in alphabet">
            <li :key="indexs">
              <!--:href="`#${item}`" -->
              <a @click.stop="toTegional(item)" @touchstart.stop="handleTouchStart" @touchmove.stop="handleTouchMove" @touchend.stop="handleTouchEnd">
                <span :class="{ isActive: mark == item }">{{ item }}</span>
              </a>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <transition name="fade">
      <div :class="['tegionalBox']" v-if="Tegional != 0">{{ Tegional }}</div>
    </transition>
  </mt-popup>
</template>

<script>
import Letter from '@/views/controllers/Letter.js';
const getAlphabet = function() {
  var arr = [];
  for (var i = 65; i < 91; i++) {
    arr.push(String.fromCharCode(i));
  }
  return arr;
};
export default {
  mixins: [Letter],
  components: {},
  data() {
    return {
      isScroll: true,
      Tegional: 0,
      flag: -1,
      flagI: -1,
      contacts: '',
      touchStatus: false,
      startY: 0,
      timeer: null,
      timeer2: null,
      timeer1: null,
      skList: [
        {
          width: '65%',
          row: 5
        },
        {
          width: '20%',
          position: 'right'
        },
        {
          width: '40%',
          inline: true
        },
        {
          width: '50%'
        },
        {
          width: '30%',
          ml: '.1rem'
        },
        {
          width: '15%',
          inline: true
        },
        {
          width: '100%',
          inline: true
        },
        {
          width: '0',
          inline: true
        }
      ],
      dataList: [],
      serchDataList: [],
      historyList: [],
      alphabet: getAlphabet(), // 锚点的生成
      isShowPopup: false, // 组件的开启和关闭
      mark: 'A',
      storageType: 'chosePhonePerson' // 存储你的历史城市key值
    };
  },
  watch: {
    isShowPopup() {
      if (this.isShowPopup) {
        this.setRouteListeners('choisePersonGoBack'); //保存当前路由返回事件记录
        this.$root.$eventHub.$on('choisePersonGoBack', data => {
          this.choisePersonGoBack();
          this.$root.$eventHub.$off('choisePersonGoBack');
        });
      } else {
        this.$emit('close'); //销毁组件
        this.removeRouteListeners('choisePersonGoBack'); //返回删除记录
      }
    }
  },
  created() {
    this.serchDataList = this.dataList;
  },
  beforeDestroy() {
    clearTimeout(this.timeer);
    clearTimeout(this.timeer1);
    clearTimeout(this.timeer2);
  },
  methods: {
    handleTouchStart() {
      this.touchStatus = true;
    },
    handleTouchMove(e) {
      if (this.touchStatus) {
        if (this.timeer) {
          clearTimeout(this.timeer);
        }
        this.timeer = setTimeout(() => {
          const touchY = e.touches[0].clientY - 40; // 手指触摸当前位置距离视口顶部的距离减去40（40指滚动区域最上边和页面顶部之间的距离）
          const index = Math.floor((touchY - this.startY) / 26); // 手指触摸当前位置所在的字母索引（26指单个字母的高度）
          if (index >= 0 && index < this.alphabet.length) {
            this.toTegional(this.alphabet[index]);
          }
        }, 16);
      }
    },
    handleTouchEnd() {
      this.touchStatus = false;
    },
    onSearch() {
      let arr = [];
      for (var i = 0; i < this.dataList.length; i++) {
        //for循环数据中的每一项（根据name值）
        if (this.dataList[i].name.search(this.contacts) != -1) {
          arr.push(this.dataList[i]);
        }
      }
      this.serchDataList = arr;
    },
    // 滚动监听
    onScroll(event) {
      var allEle;
      for (var i = 0; i < this.alphabet.length; i++) {
        allEle = document.getElementById(`_${this.alphabet[i]}`);
        if (allEle && event.target.scrollTop >= allEle.offsetTop - 140) {
          this.mark = this.alphabet[i];
        }
      }
      if (document.getElementsByClassName('choiceAdress')[0].scrollTop == 0) {
        this.mark = this.alphabet[0];
      }
      let top = event.srcElement.scrollTop;
      this.isScroll = top > 10 ? false : true;
    },
    // 锚点定位
    toTegional(index) {
      var id = '#_' + index;
      if (index == this.alphabet[0]) {
        document.getElementsByClassName('choiceAdress')[0].scrollTop = 0;
        this.isScroll = true;
      } else {
        document.querySelector(id) && document.querySelector(id).scrollIntoView(true);
      }
      if ('vibrate' in window.navigator) {
        window.navigator.vibrate(20);
      }
      this.Tegional = index;
      this.mark = index;
      let _this = this;
      this.timeer1 = setTimeout(function() {
        _this.Tegional = 0;
      }, 500);
    },
    // 组件引用方式入口
    showPopup(data) {
      this.choseItem = data;
      this.isShowPopup = true;
      (this.$cookies.get('tenant') == 'dsm'||this.$cookies.get('tenant') == 'novartis') ? this.GetAllContacts() : this.reloadPerson();
    },
    GetAllContacts() {
      let params = {
        userId: this.$cookies.get('userId')
      };
      if(this.$cookies.get('tenant') == 'novartis'){
        params.proposalId=this.SmartStorage.get('proposalId')
      }
      let _this = this;
      this.services.GetAllContacts(params).then(res => {
        if (res.success) {
          _this.makeAlphabet(res.content);
        }
      });
    },
    compare(property) {
      return function(a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1.charCodeAt() - value2.charCodeAt();
      };
    },
    makeAlphabet(data) {
      let py = '';
      this.alphabet = [];
      data.forEach((element, index) => {
        element.select = false;
        element.phoneNumber = element.phoneNumber ? element.phoneNumber : element.phone;
        element.phoneNumbers = element.phoneNumbers
          ? element.phoneNumbers
          : [
              {
                label: '',
                phoneNumber: element.phone
              }
            ];
        py = this.isChinese(element.name, '', true) ? this.getPinYinFirstCharacter(element.name, '', true) : this.getPinYin(element.name, '', true);
        element.py = py.substr(0, 1);
        if (this.choseItem.name == element.name) {
          element.select = true;
        }
      });
      this.dataList = data.sort(this.compare('py'));
      var allCityPy = this.dataList.map(ele => {
        return ele.py;
      });
      this.alphabet = getAlphabet().filter(ele => {
        return allCityPy.includes(ele);
      });
      this.mark = this.alphabet[0];
      this.serchDataList = this.dataList;
      console.log(this.dataList);
      //   alert(JSON.stringify(this.dataList))
    },
    reloadPerson() {
      let _this = this;
      this.iJsBridge.call({
        method: 'SMContacts.getContacts',
        postData: {
          key: [_this.storageType]
        },
        callback: function(data) {
          _this.makeAlphabet(data);
        }
      });
    },
    choisePersonGoBack() {
      this.contacts = '';
      this.isShowPopup = false;
      this.$set(this.$parent, 'hideEditPage', false);
    },
    // 选择电话
    chosePhone(item, phoneNumber) {
      this.choseItem = {
        phoneNumber: phoneNumber.phoneNumber,
        name: item.name
      };
      console.log(this.choseItem);
      this.$emit('chosePPerson', this.choseItem);
      this.choisePersonGoBack();
    },
    touchPhoneItem(index, nindex) {
      this.flag = nindex;
      this.flagI = index;
      let _this = this;
      this.timeer2 = setTimeout(function() {
        _this.flag = -1;
        _this.flagI = -1;
      }, 500);
    }
  }
};
</script>

<style scoped="scoped" lang="scss">
@import '@/assets/platform/index.scss';

.popup_box {
  font-size: 0.14rem;
  width: 100% !important;
  height: 100% !important;
  box-sizing: border-box !important;
  background-color: #fff !important;
}
/deep/.searchRowContent {
  width: 2.9rem;
  margin-left: .15rem;
 
  input {
    border-radius: 0.04rem !important;
  }
}
/deep/.searchRowInput{
  input {
    height:.3rem;
    line-height: .3rem;
  }
}
/deep/.searchRowDeleteSvg {
  position: fixed;
  right: 0.3rem;
  top: 0.45rem;
  use{
    fill: #909399;
  }
}
/deep/.searchRowSvg{
  top: .08rem;
}
</style>
